const jwt = require('jsonwebtoken');

const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY;
const METABASE_SITE_URL = process.env.VUE_APP_METABASE_SITE_URL;

export default (number, question = false) => {
    const payload = {
        resource: question ? { question: number } : { dashboard: number },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60,
    };

    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    return `${METABASE_SITE_URL}/embed/${question ? 'question' : 'dashboard'}/${token}#bordered=true&titled=true`;
};

export const representativeReport = ({ number, email, isRepresentative = false }) => {
    const params = {};

    if (isRepresentative) {
        params.email = email;
    }
    const payload = {
        resource: { dashboard: number },
        params,
        exp: Math.round(Date.now() / 1000) + 10 * 60,
    };

    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
};
